import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import ContentForm from "../../components/GSTTemp/ContentForm";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstAdvisoryService() {
  // /* Slider */
  // const advISliders = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `GST Advisory Services`,
  //     content: `India's Best GST Advisory Services`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online GST Advisory Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online GST Advisory `,
  //     content: `Best GST Advisory Services in Hosur`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online GST Advisory Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online GST Advisory Service`,
  //     content: `Best GST Advisory Services Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online GST Advisory Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST Advisory Service`,
    buyBtnLink: `#pricing-buy`,
    price: `499`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const advAboutData = {
    header: `GST Advisory Services`,
    sub_title: `For Personal and Business Related GST Advisory Service Providers in Hosur.`,
    content_paragraph: [
      // `TODAYFILINGS has had a direct hand in offering GST Advisory Services.The Goods and Services Tax was
      //  established by the Indian government on July 1st, 2017. (GST). It is used
      // for all business operations carried out in India.  `,
      // `Since India's independence, the GST is the country's biggest indirect tax reform.
      //  GST is a single, uniform tax assessed across the entire country of India on
      //   both commodities and services.`,

      `We offer a wide range of GST advising services, such as discussing how GST 
      would affect our clients' businesses, looking at various market situations and 
      business models, helping with legislative business level implementation, 
      transition management, and carrying out important compliance.`,
    ],
  };

  /* Scroll Nav Data */
  const avdScrollId = [
    {
      id: `#benefits`,
      heading: `Benefits
      `,
    },
    {
      id: `#checklist`,
      heading: `CheckList`,
    },
    {
      id: `#ourrole`,
      heading: `Our Role`,
    },
  ];

  /* Img Content Component Data */
  const avdIcData = {
    id: 'benefits',
    mt_div: '',
    mt_img: '',
    header: 'Benefits Of GST Registration',
    image: '/imgs/registration/ipr/td9.png',
    alt_tag: 'Benefits Of GST Registration',
    points: [
      {
        content: ` GST eliminates the tax's cascading effect.`,
        icon: true,
      },
      {
        content: `Greater bar for registration.`,
        icon: true,
      },
      {
        content: `Scheme for the composition of small firms.`,
        icon: true,
      },
      {
        content: `Fewer people are complying.`,
        icon: true,
      },
      {
        content: `Operators in e-commerce are given specific consideration.`,
        icon: true,
      },
      {
        content: `Increased effectiveness.`,
        icon: true,
      },
      {
        content: `The GST regulates unorganised industries.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const cancelCiData = {
    id: 'ourrole',
    background: '',
    mt_div: 'mt-5',
    header: 'GST Advisory Services- Our Role',
    paragraph: `Evaluation Of Key Parameters For The Fiscal Impact Of GST On Business And Product Pricing Involves:`,
    points: [
      {
        content: `supplies, stock transfers, price cuts, unrelated parties' supplies, and incidental supplies.`,
        icon: true,
      },
      {
        content: `altering the rules for when and where commodities or services are supplied, or both.`,
        icon: true,
      },
      {
        content: `assessing the effect on external supply, such as products and services.`,
        icon: true,
      },
      {
        content: `GST valuation methodology, including impact on free costs.`,
        icon: true,
      },
      {
        head: `Advise On Realigning The Operations To Ensure Tax Efficiency:`,
        content: ``,
        icon: false,
      },
      {
        content: `Finding alternative company models to maximise credit and assure tax efficiency`,
        icon: true,
      },
      {
        content: `depending on the associated tax costs, evaluation of the identified alternative business models.`,
        icon: true,
      },
      {
        content: `Determine potential hazards and provide appropriate mitigating measures in connection with the selected models.`,
        icon: true,
      },
      {
        content: `After talking with the management, specify the business model and contractual situations that could be used for execution.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/ngo/80g-registration.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* Content Form Component Data */
  const GstAdvCfData = {
    id: 'checklist',
    // mt_div: "mt-5",
    // mt_img: "mt-5",
    header: 'Checklist for Eligibility to GST Registration',
    content: `The GST Act, 2017, Sections 22 to 30, deal with the
    registration process for each supplier of goods and
    services. Note regarding GST registration beginning on
    April 1, 2019.`,
    body: [
      {
        head: '',
        points: `Every
        person who engages in the provision of services and
        whose aggregate turnover exceeds Rs. 40 lakhs for the
        rest of the States and Rs. 20 lakhs for Manipur,
        Mizoram, Nagaland, and Tripura shall be required to
        register under this act in the state or union
        territory from which he makes taxable supplies of
        goods or services or both.`,
        icon: true,
      },
      {
        head: '',
        points: `Every
        person who engages in the supply of goods and whose
        annual gross receipts exceed Rs. 40 lakh (for the
        States of Arunachal Pradesh, Manipur, Meghalaya,
        Mizoram, Nagaland, Puducherry, Sikkim, Telangana,
        Tripura, and Uttarakhand) or Rs. 40 lakh (for the rest
        of the States) shall be required to register under
        this act in the state or union territory from which he
        makes taxable supplies of goods or services or both`,
        icon: true,
      },
      {
        head: '',
        points: `To be
        eligible for tax exemption on the export of services
        outside of India, companies that export services must
        also complete the GST migration process and receive a
        GST Registration number.`,
        icon: true,
      },
      {
        head: '',
        points: `In case of
        inter-state sales, GST Registration is mandatory
        irrespective of turnover.`,
        icon: true,
      },
      {
        head: 'GST Rates',
        points: `The types of goods and services will affect the GST
        rates. The slab rates are 5%, 12%, 18%, and 28% at the
        moment. Rough stones are subject to a fee of 0.25% and
        gold at 3%.`,
        icon: true,
      },
    ],
  };

  /* FaQ data */
  var GSTAdviceFAQ = [
    {
      header: 'What is GST-IN?',
      body: [
        {
          content: `All organizations that successfully register for GST are given a
          special GST-IN, or Goods and Services Tax Identification Number.`,
          icon: false,
        },
      ],
    },
    {
      header: 'When should a business apply for multiple GST registration?',
      body: [
        {
          content: `If a business operates from more than one state, then a separate GST registration is 
          required for each state. For instance, If a sweet vendor sells in Karnataka and Tamil Nadu, he 
          has to apply for separate GST registration in Karnataka and TN respectively. A business with multiple 
          business verticals in a state may obtain a separate registration for each business vertical.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is GST part of MRP?',
      body: [
        {
          content: `As the name itself says Maximum Retail Price (MRP) is the maximum price the seller can charge 
          from the buyer. MRP is inclusive of all taxes including GST. It must be noted that retailers cannot charge
           GST over and above the MRP. GST is already included in the MRP printed on the product.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is separate registration required for trading and manufacturing by same entity in one state?',
      body: [
        {
          content: `There will be only one registration per State for all activities. But, you have the option to be 
          registered as a separate business vertical.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Not liable to tax as mentioned u/s 23 of CGST means nil rated supply or abated value of supply? ',
      body: [
        {
          content: `Not liable to tax means supplies which is not leviable to tax under the CGST/SGST/IGST Act.
           Please refer to definition under Section 2(78) of the CGST Act.`,
          icon: false,
        },
      ],
    },
    {
      header:
        '  What is the difference between legal name and trade name in GST?  ',
      body: [
        {
          content: `The legal name is a name that is registered at the time of incorporation of a company or business.
           The legal name is generally the name of the company on the PAN. On the other hand, the trade name is the 
           name that is used in normal business practices or common parlance.`,
          icon: false,
        },
      ],
    },

    // 2
    {
      header: 'Is GST mandatory for interstate purchase?',
      body: [
        {
          content: `No matter if they exceed the Rs 20 lakh barrier for GST
          registration, everybody conducting interstate supplies is required
          to register under section 24 of the CGST Act. A seller must be
          GST-registered if a buyer who is not registered wants to make an
          interstate purchase.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the three types of GST?`,
      body: [
        {
          content: `In India, there are three different types of GST: Integrated Goods
          and Services Tax (IGST), State Goods and Services Tax (SGST), and
          Central Goods and Services Tax (CGST) (IGST).`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `What is the difference between legal name and trade name in GST?`,
      body: [
        {
          content: `A company or business's legal name is the name that is registered at
          the moment of incorporation. Typically, the firm name listed on the
          PAN corresponds to the legal name. The trade name, on the other
          hand, is the term that is employed in customary business procedures
          or everyday speech.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `Why is there on GST on petrol?`,
      body: [
        {
          content: `According to a top source today, gasoline and diesel will not be
          subject to the Goods and Services Tax (GST) in the near future
          because neither the federal government nor any of the states are
          in favour due to concerns about significant income loss.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Gst Advisory Services'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={advISliders} /> */}

          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={advAboutData} />
          <Counter />
          <ScrollNav scroll_data={avdScrollId} />
          <ImgContent item={avdIcData} />

          <ContentForm CFSection_data={GstAdvCfData} />
          <ContentImg CISection_data={cancelCiData} />

          <Cta />

          <Guidance />

          <FAQAccord items={GSTAdviceFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
